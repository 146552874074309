import { admin2 } from '@/api/instance'

export const GetMemberConfig = async ({ shopId }) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/memberConfig`,
  })
}

export const UpdateMemberConfig = async ({
  shopId,
  defaultLevel,
  expireNotificationBefore,
  displayCardLevelName,
  displayCardPhoneNumber,
  cardStyle,
  CustomCardTemplateId,
}) => {
  return await admin2({
    method: 'PUT',
    url: `/${shopId}/memberConfig`,
    data: {
      defaultLevel,
      expireNotificationBefore,
      displayCardLevelName,
      displayCardPhoneNumber,
      cardStyle,
      CustomCardTemplateId,
    },
  })
}
