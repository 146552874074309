import { admin2 } from '@/api/instance'

export const CreateCustomCardTemplate = async ({
  shopId,
  name,
  cardSettings,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/customCardTemplate`,
    data: {
      name,
      cardSettings,
    },
  })
}

export const GetCustomCardTemplate = async ({
  shopId,
  start,
  limit,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/customCardTemplate`,
    params: {
      start,
      limit,
    },
  })
}

export const GetCustomCardTemplateCount = async ({
  shopId,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/customCardTemplate/count`,
  })
}

export const FindCustomCardTemplate = async ({
  shopId,
  id,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/customCardTemplate/${id}`,
  })
}

export const UpdateCustomCardTemplate = async ({
  shopId,
  id,
  name,
  cardSettings,
}) => {
  return await admin2({
    method: 'PUT',
    url: `/${shopId}/customCardTemplate/${id}`,
    data: {
      name,
      cardSettings,
    },
  })
}

export const DeleteCustomCardTemplate = async ({
  shopId,
  id,
}) => {
  return await admin2({
    method: 'DELETE',
    url: `/${shopId}/customCardTemplate/${id}`,
  })
}
